import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index";
Vue.use(Vuex);

import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage("secret-key-value", {
  prefix: "@res",
  encAlgorithm: "Rabbit",
});
export default new Vuex.Store({
  state: {
    connection_snack: false,
    drawer: true,
    mini: false,
    def_path: "https://app.rsst.app",
    //def_path: "http://localhost:8000",
    token: encryptStorage.getItem("token_restline") || null,
    restline: encryptStorage.getItem("restline") || {
      is_store: true,
    },

    break_point: 0,
    dash_lang: "AR",
    userPermissions: [],
    storePermissions: [],
    casher: {
      products: [],
      additions: [],
    },
    translations: {
      done: false,
      data: [],
    },
    packages: {
      done: false,
      data: [],
    },
    langs: {
      done: false,
      data: [],
    },
    cats: {
      done: false,
      data: [],
    },
    branches: {
      done: false,
      data: [],
    },
    additions: {
      done: false,
      data: [],
    },
    notes: {
      done: false,
      data: [],
    },
    deliveries: {
      done: false,
      data: [],
    },
    drivers: {
      done: false,
      data: [],
    },
    groups: {
      done: false,
      data: [],
    },
    employees: {
      done: false,
      data: [],
    },
    domain: null,
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    is_store(state) {
      if (state.restline) {
        state.restline.is_store ? true : false;
      }
    },
    items: (stat) => (items) => {
      return stat[items];
    },
    default_lang(state) {
      if (state.langs.done) {
        return state.langs.data.find((l) => l.is_default);
      }
      return {
        locale: "AR",
      };
    },
    casher: (state) => (items) => {
      return state.casher[items];
    },
    has_casher(state) {
      return !!state.casher.products.length;
    },
    TranslatePage: (state) => (page) => {
      return state.translations.data[page];
    },
    TransName: (state, getters) => (prop) => {
      const page = getters.TranslatePage(prop.page);
      if (page) {
        return (
          page.items.find((e) => e.name == prop.name).prop[state.dash_lang] ||
          page.items.find((e) => e.name == prop.name).prop[
          getters.default_lang.locale
          ]
        );
      } else {
        return "";
      }
    },
    getLocaleCookie() {
      let cookieArr = document.cookie.split(";");

      // Loop through the array elements
      for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
          and compare it with the given string */
        if ("locale" == cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
        }
      }

      // Return null if not found
      return null;
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    logout(state) {
      state.token = null;
      encryptStorage.removeItem("token_restline");
      encryptStorage.removeItem("restline");

      state.langs.done = false;
      state.langs.data = [];
      state.cats.done = false;
      state.cats.data = [];
      state.branches.done = false;
      state.branches.data = [];
      state.additions.done = false;
      state.additions.data = [];
      state.deliveries.done = false;
      state.deliveries.data = [];
      state.groups.done = false;
      state.groups.data = [];
      state.employees.done = false;
      state.employees.data = [];
    },
    user(state, user) {
      state.restline = user;
    },
    store_items(state, items) {
      state[items.items].data = items.data;
      if (items.data.length) {
        state[items.items].done = true;
      }
    },
    update_props(state, obj) {
      state[obj.items].data[obj.index][obj.prop] = obj.val;
    },
    casherProducts(state, data) {
      state.casher.products = data.map(function (e) {
        return {
          name: e.Name,
          id: e.Id,
          prices: [],
        };
      });

      data.forEach((e, i) => {
        e.ProductGroups.forEach((g) => {
          let prices = g.GroupProducts.map(function (s) {
            return {
              id: s.ProductId,
              name: s.Name,
            };
          });
          state.casher.products[i].prices = prices;
        });

        e.RemarkGroups.forEach((g) => {
          let additions = g.Remarks.map(function (r) {
            return {
              id: r.Id,
              name: r.Name,
            };
          });
          state.casher.additions.push(...additions);
        });
      });
    },
    savePermissions(state, data) {
      state.userPermissions = data.userPermissions;
      state.storePermissions = data.storePermissions;
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        formData.append("password", cred.password);
        var config = {
          method: "post",

          url: "https://app.rsst.app/api/login",
          // url: "http://127.0.0.1:8000/api/login",
          data: formData,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            encryptStorage.setItem("token_restline", token);
            encryptStorage.setItem("restline", res.data.data);
            context.commit("login", token);
            context.commit("user", res.data.data);
            context.dispatch("get_langs");
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };
        axios(config)
          .then((res) => {
            resolve(res);
            context.state.connection_snack = false;
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            if (!err.response) {
              context.state.connection_snack = true;
            } else {
              reject(err);
            }
            if (err.response.status == 401) {
              context.commit("logout");
              router.push("/");
            }
          });
      });
    },
    async get_langs(context, cred) {
      await context
        .dispatch("public__request", {
          config: {
            url: context.state.restline.is_store ?
              //  "http://localhost:8000/api/v2/store/languages"
              // : "http://localhost:8000/api/v2/super/languages",
              "https://app.rsst.app/api/v2/store/languages"
              : "https://app.rsst.app/api/v2/super/languages",
            method: "get",
          },
        })
        .then((res) => {
          const data = res.data.data;
          if (!encryptStorage.getItem("restline").is_store) {
            context.commit("store_items", {
              items: "langs",
              data: data,
            });
          } else {
            context.commit("savePermissions", data);

            context.commit("store_items", {
              items: "langs",
              data: data.langs,
            });
            context.commit("store_items", {
              items: "translations",
              data: data.translations,
            });

            context.commit("store_items", {
              items: "drivers",
              data: data.drivers,
            });

            context.state.domain = data.domain;

            context.state.dash_lang =
              context.getters.getLocaleCookie ||
              context.getters.default_lang.locale;
          }
        });
    },
    casherProducts(context) {
      context
        .dispatch("public__request", {
          config: {
            url: `store/casherProducts`,
            method: "get",
          },
        })
        .then((res) => {
          context.commit("casherProducts", res.data.data);
        });
    },
  },
  modules: {},
});
